import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AssignService } from '../assign.service';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import * as assignActions from './assign.actions';
import { resetCurrentAssign, updateAssignOrder } from './assign.actions';
import { Store } from '@ngrx/store';
import { orderBy } from 'lodash-es';
import { AppState } from '../../store/models/app.state';
import { AssignModel } from '../assign.model';
import { errorPopup, messagePopup } from '../../store/actions/ui.actions';

@Injectable()
export class AssignEffects {
  actions = inject(Actions);
  assignService = inject(AssignService);
  store = inject(Store<AppState>);

  getAssignsByProjectId = createEffect(() =>
    this.actions.pipe(
      ofType(assignActions.getAssignsByProjectId),
      mergeMap(({ projectId }) =>
        this.assignService.getAssignsByProject(projectId).pipe(
          mergeMap(res => {
            return [assignActions.updateAssignList({ assigns: res }), assignActions.loadAssignmentsCommentsCounters()];
          }),
          catchError(() => {
            return [];
          }),
        ),
      ),
    ),
  );
  getAssignById = createEffect(() =>
    this.actions.pipe(
      ofType(assignActions.getAssignById),
      mergeMap(({ id }) =>
        this.assignService.getAssign(id).pipe(
          mergeMap(res => [assignActions.updateCurrentAssign({ assign: res })]),
          catchError(() => {
            return [];
          }),
        ),
      ),
    ),
  );
  deleteAssignById = createEffect(() =>
    this.actions.pipe(
      ofType(assignActions.deleteAssign),
      withLatestFrom(this.store.select(state => state.assign.list)),
      mergeMap(([deleteData, list]) =>
        this.assignService.deleteAssign(deleteData.id, deleteData.deleteGroup).pipe(
          mergeMap(() => {
            const currentItem = list.find(item => item.id === deleteData.id);

            let reorderedElements: AssignModel[];
            if (!!currentItem.groupName && !deleteData.deleteGroup) {
              // ungroup
              reorderedElements = list
                .filter(item => item.parentId === currentItem.id) // filter children
                .map(item => {
                  return {
                    ...item,
                    parentId: null,
                  };
                });
              const newRootList = orderBy(
                list.filter(item => !item.parentId && item.id !== deleteData.id),
                ['orderPriority'],
                'asc',
              );
              reorderedElements = [...newRootList, ...reorderedElements].map((item, index) => ({
                ...item,
                orderPriority: index,
              }));
            } else {
              reorderedElements = list
                .filter(
                  item => item.orderPriority > currentItem.orderPriority && item.parentId === currentItem.parentId,
                )
                .map(item => ({
                  ...item,
                  orderPriority: item.orderPriority - 1,
                }));
            }

            return [
              resetCurrentAssign(),
              assignActions.removeAssignFromList({ id: deleteData.id }),
              updateAssignOrder({ items: reorderedElements }),
              messagePopup({ message: 'Successfully delete an Assignment' }),
            ];
          }),
          catchError(() => {
            return [errorPopup({ error: 'There is a problem with deleting this assignment' })];
          }),
        ),
      ),
    ),
  );
  updateAssignOrder = createEffect(() =>
    this.actions.pipe(
      ofType(assignActions.updateAssignOrder),
      withLatestFrom(this.store.select(state => state.project.current.project.id)),
      mergeMap(([assignmentData, projectId]) => {
        return this.assignService.updateAssign(assignmentData.items).pipe(
          mergeMap(() => [assignActions.getAssignsByProjectId({ projectId })]),
          catchError(() => {
            return [errorPopup({ error: 'There is a problem with reordering this assignment' })];
          }),
        );
      }),
    ),
  );

  loadCommentsCounters = createEffect(() =>
    this.actions.pipe(
      ofType(assignActions.loadAssignmentsCommentsCounters),
      mergeMap(() => {
        return this.assignService
          .getCommentsCounter()
          .pipe(mergeMap(res => [assignActions.populateCommentsCounters({ counters: res })]));
      }),
    ),
  );
}
