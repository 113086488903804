import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { BaseComponent } from '../../shared/base.class';
import { takeUntil } from 'rxjs/operators';
import { AppState } from '../../store/models/app.state';
import { Store } from '@ngrx/store';
import { getUserBySub } from '../../user/store/user.actions';
/*import { getUserPermissions } from '../../permission/store/permission.actions';*/

@Component({
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent extends BaseComponent implements OnInit, OnDestroy {

  constructor(private auth0: AuthService, private store: Store<AppState>) {
    super();
  }

  ngOnInit(): void {

    this.auth0.user$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (user: User) => {
        this.store.dispatch(getUserBySub({ user }));
        /*this.store.dispatch(getUserPermissions());*/
      }
    })

  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
