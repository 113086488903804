import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { AppState, getState } from '../../store/models/app.state';
import { Store } from '@ngrx/store';
import { catchError, mergeMap } from 'rxjs/operators';
import {  globalLoading } from '../../store/actions/ui.actions';
import { ISite } from '../../project/sites-and-study-team/study-and-study-team-manage/site.interface';
import {
  loadSitesList,
  populateCurrentSite,
  populateCurrentSiteSnapshot,
  populateSitesList,
  searchSiteById
} from './site.actions';
import { ProjectService } from '../../project/project.service';

@Injectable()
export class SiteEffects {
  actions = inject(Actions);
  projectService = inject(ProjectService);
  store = inject(Store<AppState>);

  searchSiteById = createEffect(() => {
    return this.actions.pipe(
      ofType(searchSiteById),
      mergeMap(({ id }) =>
        this.projectService.getSiteById(id).pipe(
          mergeMap((res: ISite) => [
            (populateCurrentSite({ site: res })),
            (populateCurrentSiteSnapshot({ site: res })),
            (globalLoading(false))
          ]),
          catchError(err => {
            return [(globalLoading(false))];
          })
        )
      ));
  });

  // createProject = createEffect(() => {
  //   return this.actions.pipe(
  //     ofType(createProject),
  //     mergeMap(({ project }) =>
  //       this.projectService.create(project).pipe(
  //         mergeMap((res: ProjectModel) => [
  //           (addProject({ project: res })),
  //           (pendingCreateProject({ pending: false })),
  //           (populateCurrentProject({ project: res })),
  //           (populateCurrentProjectSnapshot({ project: res })),
  //           (successCreateProject({ success: true })),
  //           (globalLoading(false))
  //         ]),
  //         catchError(err => {
  //           return [(globalLoading(false))];
  //         })
  //       )
  //     ));
  // });
  //
  // updateProject = createEffect(() => {
  //   return this.actions.pipe(
  //     ofType<ReturnType<typeof updateProject>>(updateProject),
  //     mergeMap(({ project }) =>
  //       this.projectService.update(project).pipe(
  //         mergeMap(res => [
  //           (populateCurrentProject({ project: res })),
  //           (populateCurrentProjectSnapshot({ project: res })),
  //           (pendingCreateProject({ pending: false })),
  //           (successCreateProject({ success: true })),
  //           (messagePopup({ message: 'Study updated' })),
  //           (globalLoading(false))
  //         ]),
  //         catchError(() => [
  //           (globalLoading(false)),
  //           (successCreateProject({ success: false })),
  //           (pendingCreateProject({ pending: false })),
  //         ]))
  //     ));
  // });

  loadSites = createEffect(() => {
    return this.actions.pipe(
      ofType(loadSitesList),
      mergeMap(() =>
        this.projectService.getSites().pipe(
          mergeMap(res => [
            (populateSitesList({ sites: res })),
            (globalLoading(false))
          ]),
          catchError(() => [
            (globalLoading(false))
          ])
        )));
  });
  //
  // deleteProject = createEffect(() => {
  //   return this.actions.pipe(
  //     ofType(deleteProject),
  //     mergeMap(({ id }) =>
  //       this.projectService.deleteProject(id).pipe(
  //         mergeMap(() => [
  //           (removeProject({ id })),
  //           (messagePopup({ message: 'Successfully delete a Study' }))
  //         ]),
  //         catchError(() => [(errorPopup({ error: 'There is a problem with deleting this Study' }))])
  //       )
  //     ));
  // });
  //
  // getRecordForms = createEffect(() => {
  //   return this.actions.pipe(
  //     ofType(getRecordForms),
  //     mergeMap(({ datasetId, projectId }) =>
  //       this.projectService.getRecordFormsPerProject(projectId, datasetId).pipe(
  //         mergeMap(res => [
  //           (populateRecordForms({ recordForms: res }))
  //         ]),
  //         catchError(() => [])
  //       )
  //     ));
  // });
  //
  // refreshCurrentProject = createEffect(() => {
  //   return this.actions.pipe(
  //     ofType(refreshCurrentProject),
  //     mergeMap(() => [
  //       (searchProjectById({
  //         id: getState(this.store).project.current.project.id
  //       }))
  //     ]),
  //     catchError(() => []));
  // });
  //
  // updateProjectField = createEffect(() => {
  //   return this.actions.pipe(
  //     ofType(updateProjectField, updateProjectFields, successCreateProject, populateCurrentProjectSnapshot),
  //     mergeMap(() => {
  //       const current = omit(getState(this.store).project.current.project, ['userLastNameField', 'userFirstNameField']);
  //       const snapshot = omit(getState(this.store).project.current.projectSnapshot, ['userLastNameField', 'userFirstNameField']);
  //       return [
  //         checkCurrentProjectForPendingChanges({
  //           hasChanges: !isEqual(current, snapshot)
  //         })
  //       ]
  //     }),
  //     catchError(() => []));
  // });
}

