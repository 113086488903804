import { inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState, getState } from "../../store/models/app.state";
import { EventService } from "../event.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, mergeMap, switchMap, tap } from "rxjs/operators";
import { errorPopup, globalLoading, messagePopup } from "../../store/actions/ui.actions";
import {
  checkCurrentEventForPendingChanges,
  createProjectEvent,
  deleteProjectEvent, getProjectEventsCommentsCounter,
  loadEventNotificationsListByEventId,
  loadProjectEventListByProjectId,
  pendingCreateUpdateEvent,
  populateCurrentProjectEvent,
  populateCurrentProjectEventSnapshot, populateProjectEventsCommentsCounter,
  removeProjectEvent,
  successCreateUpdateEvent,
  updateEventField,
  updateEventNotificationsList,
  updateProjectEvent,
  updateProjectEventInList,
  updateProjectEventList
} from "./event.actions";
import { isEqual } from "lodash-es";
import { ProjectEventModel } from "../project-event.model";
import { EventNotificationsService, IEventNotificationModel } from '../event-notifications';

@Injectable()
export class EventEffects {

  store = inject(Store<AppState>);
  actions = inject(Actions);
  eventService = inject(EventService);
  eventNotificationsService = inject(EventNotificationsService);

  updateEventField = createEffect(() => {
    return this.actions.pipe(
      ofType(updateEventField, successCreateUpdateEvent),
      mergeMap(() => {
        return [
          checkCurrentEventForPendingChanges({
            changes: !isEqual(getState(this.store).event.current.projectEvent.event,
              getState(this.store).event.current.projectEventSnapshot.event)
          })
        ];
      }),
      catchError(() => []));
  });
  deleteProjectEvent = createEffect(() => {
    return this.actions.pipe(
      ofType(deleteProjectEvent),
      mergeMap(({ id }) =>
        this.eventService.deleteProjectEvent(id).pipe(
          mergeMap(() => [
            removeProjectEvent({ projectEventId: id }),
            messagePopup({ message: "Successfully deleted" }),
          ]),
          catchError(() => {
            return [
              errorPopup({
                error: "There is a problem with deleting this item",
              }),
            ];
          })
        )
      )
    );
  });
  // project events
  updateProjectEvent = createEffect(() => {
    return this.actions.pipe(
      ofType(updateProjectEvent),
      mergeMap(({ projectEvent }) => {
          return this.eventService.updateProjectEvent(projectEvent).pipe(
            mergeMap((res: ProjectEventModel) => {
              return [
                (loadProjectEventListByProjectId({ id: res.projectId })),
                (updateProjectEventInList({ projectEvent: res })),
                (populateCurrentProjectEvent({ projectEvent: res })),
                (populateCurrentProjectEventSnapshot({ projectEvent: res })),
                (pendingCreateUpdateEvent({ pending: false })),
                (successCreateUpdateEvent({ success: true })),
                // (messagePopup({ message: `${ this.getEventType(res) } Updated` }))
              ];
            }),
            catchError(() => {
              return [];
            })
          )
        }
      ));
  });
  createProjectEvent = createEffect(() => {
    return this.actions.pipe(
      ofType(createProjectEvent),
      mergeMap(({ projectEvent }) =>
        this.eventService.createProjectEvent(projectEvent).pipe(
          tap((res: ProjectEventModel) => {
          }),
          mergeMap((res: ProjectEventModel) => [
            (loadProjectEventListByProjectId({ id: res.projectId })),
            (populateCurrentProjectEvent({ projectEvent: res })),
            (populateCurrentProjectEventSnapshot({ projectEvent: res })),
            (pendingCreateUpdateEvent({ pending: false })),
            (successCreateUpdateEvent({ success: true })),
            // (messagePopup({ message: `${ this.getEventType(res) } Created` }))
          ]),
          catchError(() => {
            return [];
          })
        )
      ));
  });
  loadProjectEventListByProjectId = createEffect(() => {
    return this.actions.pipe(
      ofType(loadProjectEventListByProjectId),
      mergeMap(({ id }) =>
        this.eventService.getProjectEventListByProjectId(id).pipe(
          mergeMap((res: ProjectEventModel[]) => [
            updateProjectEventList({
              projectEventList: res || [],
            }),
            getProjectEventsCommentsCounter(),
            globalLoading(false),
          ]),
          catchError(() => {
            return [globalLoading(false)];
          })
        )
      )
    );
  });

  //event notifications
  loadEventNotificationsListByEventId = createEffect(() => {
    return this.actions.pipe(
      ofType(loadEventNotificationsListByEventId),
      switchMap(({ id }) =>
        this.eventNotificationsService.getEventNotificationsByEventId(id).pipe(
          mergeMap((eventNotifications: IEventNotificationModel[]) => [
            updateEventNotificationsList({ eventNotifications }),
            globalLoading(false),
          ]),
          catchError(() => {
            return [globalLoading(false)];
          }),
        )
      ),
    );
  });

  getEventsCommentsCounters = createEffect(() => {
    return this.actions.pipe(
      ofType(getProjectEventsCommentsCounter),
      switchMap(() =>
        this.eventService.getProjectEventsCommentsCounters().pipe(
          mergeMap((counters: any[]) => [
            populateProjectEventsCommentsCounter({ counters }),
            globalLoading(false),
          ]),
          catchError(() => {
            return [globalLoading(false)];
          }),
        )
      ),
    );
  });

}
