import { Injectable } from '@angular/core';

import { of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash-es';

import { DatasetModel } from './dataset.model';
import { AppState, getState } from '../store/models/app.state';
import { AppConfig } from '../core/config/app.config';
import { DatasetDataTypeModel } from './dataset-data-type.model';
import { DatasetDataTypeEnum } from './dataset-data-type.enum';
import { PharHttp } from '../core/http/http-client.service';
import { IDatasetList } from '../_old/_dataset/_dataset-lists/dataset-list.interface';

@Injectable({providedIn: 'root'})
export class DatasetService {

  constructor(
    private store: Store<AppState>,
    private http: PharHttp,
    private config: AppConfig
  ) {
  }

  getDatasetVersions(id: number): Observable<any> {
    const url = `${this.config.buildUri('dataset')}/DatasetVersionsHistoryById?id=${id}`;
    return this.http.get(url);
  }

  getDatasetList(): Observable<DatasetModel[]> {
    return this.http.get(this.config.buildUri('dataset') + '/DataSets').pipe(map((res: any) => res));
  }

  deleteDataset(id: number): Observable<any> {
    return this.http.delete(this.config.buildUri('dataset') + '/Dataset/?id=' + id);
  }

  create(dataset: DatasetModel): Observable<any> {
    delete dataset.id;
    dataset.organizationId = getState(this.store).user.profile.organizationId;
    dataset.userIdCreated = getState(this.store).user.profile.userId;
    return this.http.post(this.config.buildUri('dataset') + '/Dataset', dataset);
  }

  update(dataset: DatasetModel): Observable<any> {
    dataset.userIdUpdated = getState(this.store).user.profile.userId;
    return this.http.put(this.config.buildUri('dataset') + '/Dataset', dataset);
  }

  searchByIdAndVersion(id: number, version: number): Observable<any> {
    return this.http.get(`${this.config.buildUri('dataset')}/DatasetHistoryByVersion/?id=${id}&version=${version}`);
  }

  searchById(id: number): Observable<any> {
    return this.http.get(this.config.buildUri('dataset') + '/DatasetById/?id=' + id);
  }

  getDatasetLists(datasetId: number): Observable<any> {
    return this.http.get(this.config.buildUri('datasetList') + `/DatasetLists?datasetId=${datasetId}`);
  }

  deleteDatasetList(id: number): Observable<any> {
    return this.http.delete(this.config.buildUri('datasetList') + `/DatasetList?id=${id}`);
  }

  createDatasetList(title: string, datasetId: number): Observable<any> {
    return this.http.post(this.config.buildUri('datasetList') + `/DatasetList`, { title, datasetId, fields: [] });
  }

  updateDatasetList(list: IDatasetList): Observable<any> {
    return this.http.put(this.config.buildUri('datasetList') + `/DatasetList`, list);
  }

  loadDatasetDataTypes(): Observable<DatasetDataTypeModel[]> {
    const list = [];
    Object.keys(DatasetDataTypeEnum).map(key => {
      const element = {
        value: DatasetDataTypeEnum[key],
        text: key
      };
      list.push(element);
    });
    return Observable.create(observer => {
      observer.next(list);
      observer.complete();
    });

  }

  capitalizeFirstLetter(value: string): string {
    if (value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    } else {
      return value;
    }
  }

  lowercaseFirstLetter(value: string): string {
    if (value) {
      return value.charAt(0).toLowerCase() + value.slice(1);
    } else {
      return value;
    }
  }

  trim(value: string): string {
    if (value) {
      return value.replace(/\s/g, '');
    } else {
      return value;
    }
  }

  filterDatasetFields(dataset: DatasetModel, filter: string = '', highlight = false): DatasetModel {
    let filterData = {};
    const ds: DatasetModel = { ...dataset };
    if (filter.trim() === '' || isEmpty(ds)) {
      return ds;
    }
    filterData = this.searchInDatasetFields(ds, filter, highlight);
    if (isEmpty(filterData)) {
      ds.data = {};
    } else {
      ds.data = filterData;
    }
    return ds;
  }

  searchInDatasetFields(ds, filter: string, highlight = false): any {
    const filterData = {};
    let founded;
    for (const field in ds.data) {
      if (ds.data.hasOwnProperty(field)) {
        founded = { ...ds.data[field] };
        if (field.toLowerCase().trim().includes(filter.trim().toLowerCase())) {
          if (highlight) {
            founded.style = {
              backgroundColor: 'rgba(71, 117, 245, 0.3)',
              borderRadius: '5px'
            };
          }
          filterData[field] = founded;
        }
        if (founded.type === DatasetDataTypeEnum.Entity) {
          const response = this.searchInDatasetFields(founded, filter, highlight);
          if (!isEmpty(response)) {
            founded.data = response;
            filterData[field] = founded;
          }

        }
      }

    }
    return filterData;
  }

  filter(dataset: DatasetModel, filter: string, highlight = false): Observable<DatasetModel> {
    let filterData;
    const ds: DatasetModel = { ...dataset };
    this.setParent(ds);
    const founded: DatasetModel = this.search(ds, ['name', 'description'], filter || '');
    let parent: DatasetModel;
    if (founded) {
      if (highlight) {
        founded.style = {
          backgroundColor: 'rgba(71, 117, 245, 0.3)',
          borderRadius: '5px'
        };
      }
      if (founded.type === 'entity' || founded.type === 'dataset') {
        parent = founded;
      } else {
        parent = this.search(dataset, ['name'], founded.parent || '');
        if (parent.type !== 'entity') {
          parent = this.search(dataset, ['name'], parent.parent || '');
        }
      }
      if (parent) {
        filterData = parent;
      } else {
        filterData = dataset;
      }
    }
    return of(filterData);
  }

  buildDatasetTypes(data, paths, parent = ''): void {
    if (!parent) {
      parent = data.name;
    }
    for (const key in data.data) {
      if (data.data[key].type === 'entity') {
        this.buildDatasetTypes(data.data[key], paths, `${parent}.${key}`);
      } else {
        paths[`${parent}.${data.data[key].name}`] = data.data[key].type;
      }
    }
  }

  search(theObject, fields, value, searchType = 'string'): any {
    let result = null;
    if (!value || value.length === 0) {
      return theObject;
    }
    for (const prop in theObject) {
      if (theObject[prop]) {
        if (fields.indexOf(prop) > -1) {
          if (searchType === 'string' && !(theObject[prop] instanceof Object) && theObject[prop].toLowerCase().indexOf(value.toLowerCase()) > -1) {
            return theObject;
            // eslint-disable-next-line eqeqeq
          } else if (searchType === 'object' && theObject[prop] == value) {
            return theObject;
          }
        }
        if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
          result = this.search(theObject[prop], fields, value, searchType);
          if (result) {
            break;
          }
        }
      }
    }
    return result;
  }

  setParent(o: DatasetModel, parent?: DatasetModel): void {
    for (const n in o.data) {
      if (o.data !== undefined) {
        o.data[n].parent = o.name;
        o.data[n].style = {};
        this.setParent(o.data[n], o.data[n].parent);
      }
    }
  }

  createName(displayName, isEntity = false): string {
    let name = displayName;
    if (isEntity) {
      name = this.capitalizeFirstLetter(name);
      return name;
    }
    return name;
  }

}
