/* src/app/pages/auth-callback/auth-callback.component.scss */
:host {
  width: 100vw;
  height: 100vh;
}
:host div {
  width: 100vw;
  height: 100vh;
}
/*# sourceMappingURL=auth-callback.component.css.map */
