import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'phar-login-callback',
  template: '',
})
export class LoginCallbackComponent implements OnInit {
  constructor(private auth0: AuthService) {
  }

  ngOnInit(): void {
    this.auth0.loginWithRedirect();
  }
}
