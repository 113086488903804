import { Action, createReducer, on } from '@ngrx/store';
import { appAuditTrailInitialState, AuditTrailState } from './audit.state';
import {
  addAuditTrailDataFilter,
  editAuditTrailDataMeta,
  getAuditActionTypesSuccess,
  loadAuditTrailDataSuccess,
  resetAuditTrailData
} from './audit.actions';

const reducer = createReducer(appAuditTrailInitialState.auditTrail,
  on(loadAuditTrailDataSuccess, (state, { auditItems, metaData }) => ({ ...state, metaData, data: auditItems })),
  on(resetAuditTrailData, (state) => ({
    ...state,
    meta: appAuditTrailInitialState.auditTrail.metaData,
    data: [],
    actionTypes: state.actionTypes
  })),
  on(addAuditTrailDataFilter, (state, { filter }) => {
    return ({ ...state, filter: filter })
  }),
  on(editAuditTrailDataMeta, (state, { params }) => {
    return ({ ...state, metaData: { ...params } })
  }),
  on(getAuditActionTypesSuccess, (state, { actionTypes }) => {
    return ({ ...state, actionTypes })
  }),
);

export function auditTrailReducer(state: AuditTrailState, action: Action): AuditTrailState {
  return reducer(state, action);
}
