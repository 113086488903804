import { Injectable } from '@angular/core';
import { PharHttp } from '../core/http/http-client.service';
import { AppConfig } from '../core/config/app.config';
import { Observable } from 'rxjs';
import { IAuditDataParams } from './audit-data-params.interface';
import { IAuditTrailItem } from './audit-trail-item.interface';

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  constructor(
    private http: PharHttp,
    private config: AppConfig
  ) {
  }

  public loadAuditTrailData(params: IAuditDataParams, filter: {
    [key: string]: any
  }): Observable<IAuditEntriesResponse> {
    return this.http.post(this.config.buildUri('audit') + '/AuditEntries', {
      ...params,
      filter
    });
  }

  public loadAuditActionTypes(): Observable<string[]> {
    return this.http.get(this.config.buildUri('audit') + '/AuditActionTypes');
  }
}

export class IAuditEntriesResponse {
  metaData: IAuditDataParams;
  data: IAuditTrailItem[]
}
