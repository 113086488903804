import { appStateAssign, AssignState } from './assign.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as assignActions from './assign.actions';

const reducer = createReducer(appStateAssign.assign,
  on(assignActions.updateAssignList, (state, { assigns }) => ({ ...state, list: assigns })),
  on(assignActions.resetAssignList, (state) => ({ ...state, list: [] })),
  on(assignActions.updateCurrentAssign, (state, { assign }) => ({ ...state, current: assign })),
  on(assignActions.resetCurrentAssign, (state) => ({
    ...state, current: {
      createdAt: '',
      updatedAt: '',
      emails: [],
      formId: null,
      securityGroupId: null,
      organizationId: null,
      projectId: null,
      userIdCreated: null,
      userIds: [],
      userIdUpdated: null,
      formName: '',
      id: null,
      assignmentType: null,
      permissionsIds: [],
      accessUrl: '',
      securityGroupType: null,
      orderPriority: null
    }
  })),
  on(assignActions.updateAssignField, (state, { value, field }) => ({
    ...state,
    current: {
      ...state.current,
      [field]: value
    }
  })),
  on(assignActions.removeAssignFromList, (state, { id }) => {
    const index = state.list.findIndex(assign => assign.id === id);
    return index > -1 ? { ...state, list: state.list.slice(0, index).concat(state.list.slice(index + 1)) } : state;
  }),
  on(assignActions.populateCommentsCounters, (state, { counters }) => {
    let commentsCounters = {};
    // convert to object for faster access
    counters.forEach(counter => {
      commentsCounters[counter.assignmentId] = counter.commentCount;
    })
    return {
      ...state,
      commentsCounters,
    }
  }),
);

export function assignReducer(state: AssignState, action: Action): AssignState {
  return reducer(state, action);
}
