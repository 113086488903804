import * as projectActions from './project.actions';
import { appStateProject, PROJECT_INITIAL_STATE, ProjectState } from './project.state';
import { ProjectStatusEnum } from '../project.model';
import { cloneDeep } from 'lodash-es';
import { Action, createReducer, on } from '@ngrx/store';

const reducer = createReducer(
  appStateProject.project,
  on(projectActions.updateProjectList, (state, { projectList }) => ({ ...state, list: projectList })),
  on(projectActions.removeProject, (state, { id }) => {
    const index = state.list.findIndex(project => project.id === id);
    return index > -1 ? { ...state, list: state.list.slice(0, index).concat(state.list.slice(index + 1)) } : state;
  }),
  on(projectActions.resetCurrentProject, state => ({
    ...state,
    current: {
      ...state.current,
      project: { ...PROJECT_INITIAL_STATE },
      projectSnapshot: { ...PROJECT_INITIAL_STATE },
      admins: [],
      pendingChanges: false,
      isLocked: false,
      projectMatrixCommentsCounters: {},
    },
  })),
  on(projectActions.populateCurrentProject, (state, { project }) => ({
    ...state,
    current: {
      ...state.current,
      project,
      isLocked: Boolean(project.projectStatus && project.projectStatus !== ProjectStatusEnum.Draft),
    },
  })),
  on(projectActions.populateCurrentProjectSnapshot, (state, { project }) => ({
    ...state,
    current: { ...state.current, projectSnapshot: cloneDeep(project) },
  })),
  on(projectActions.updateProjectInList, (state, { project }) => {
    const updateIndex = state.list.findIndex(d => d.id === project.id);
    return updateIndex > -1
      ? {
          ...state,
          list: [...[...state.list.slice(0, updateIndex)], { ...project }, ...[...state.list.slice(updateIndex + 1)]],
        }
      : state;
  }),
  on(projectActions.pendingCreateProject, (state, { pending }) => ({
    ...state,
    current: { ...state.current, pending },
  })),
  on(projectActions.dispatchedCreateProject, (state, { dispatched }) => ({
    ...state,
    current: { ...state.current, dispatched },
  })),
  on(projectActions.successCreateProject, (state, { success }) => ({
    ...state,
    current: { ...state.current, success },
  })),
  on(projectActions.addProject, (state, { project }) => ({
    ...state,
    list: [...state.list, { ...project }],
  })),
  on(projectActions.updateProjectField, (state, { field, value }) => ({
    ...state,
    current: { ...state.current, project: { ...state.current.project, [field]: value } },
  })),
  on(projectActions.updateProjectFields, (state, { partialProject }) => ({
    ...state,
    current: { ...state.current, project: { ...state.current.project, ...partialProject } },
  })),
  on(projectActions.populateRecordForms, (state, { recordForms }) => ({
    ...state,
    recordForms,
  })),
  on(projectActions.checkCurrentProjectForPendingChanges, (state, { hasChanges }) => ({
    ...state,
    current: {
      ...state.current,
      pendingChanges: hasChanges,
    },
  })),
  on(projectActions.populateStudyAdminList, (state, { admins }) => ({
    ...state,
    current: {
      ...state.current,
      admins,
    },
  })),
  on(projectActions.removeStudyAdminFromList, (state, { id }) => ({
    ...state,
    current: {
      ...state.current,
      admins: state.current.admins.filter(admin => admin.id !== id),
    },
  })),
  on(projectActions.populateProjectMatrixItemsCommentsCounters, (state, { counters }) => {
    const projectMatrixCommentsCounters = {};
    counters.forEach(counter => {
      projectMatrixCommentsCounters[counter.projectMatrixId] = counter.commentCount;
    });

    return {
      ...state,
      current: {
        ...state.current,
        projectMatrixCommentsCounters,
      },
    };
  }),
);

export function projectReducer(state: ProjectState, action: Action): ProjectState {
  return reducer(state, action);
}
