import { appState } from '../models/app.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as settingsActions from '../actions/settings.actions';
import { SettingsState } from '../models/settings.state';

const _settingsReducerFn = createReducer(appState.settings,
  on(settingsActions.populateSettings, (state, { countries, languages, timezones }) => ({
    ...state,
    countries,
    timezones,
    languages
  })),
);

export function settingsReducer(state: SettingsState, action: Action): SettingsState {
  return _settingsReducerFn(state, action);
}
