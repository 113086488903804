import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  addDataset,
  checkCurrentDatasetForPendingChanges,
  createDataset,
  createDatasetList,
  deleteDataset,
  deleteDatasetDataList,
  fetchAllDatasetVersions, getDatasetDataLists,
  loadAllDatasetVersions,
  loadByIdAndVersion,
  loadCurrentDatasetDataList,
  loadDatasetByLatestVersion,
  loadDatasetDataLists,
  loadDatasetList,
  loadDatasetTypes,
  pendingCreateDataset,
  populateCurrentDataset,
  populateCurrentDatasetSnapshot,
  refreshCurrentDataset,
  removeDataset,
  resetCurrentDatasetDataList,
  searchDatasetById,
  successCreateDataset,
  updateDataset,
  updateDatasetDataList,
  updateDatasetField,
  updateDatasetList,
  updateDatasetTypes,
} from './dataset.actions';


import { DatasetService } from '../dataset-service.service';
import { DatasetModel } from '../dataset.model';

import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { DatasetDataTypeModel } from '../dataset-data-type.model';
import { AppState, getState } from '../../store/models/app.state';
import { Store } from '@ngrx/store';
import { errorPopup, globalLoading, messagePopup } from '../../store/actions/ui.actions';
import { isEqual } from 'lodash-es';
import { DatasetVersion } from './dataset.state';
import { IDatasetList } from '../../_old/_dataset/_dataset-lists/dataset-list.interface';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable()
export class DatasetEffect {
  store = inject(Store<AppState>);
  actions = inject(Actions);
  datasetService = inject(DatasetService);
  permissionsService = inject(NgxPermissionsService);
  loadDatasetByLatestVersion = createEffect(() => {

    let latestVersion = 0;
    let datasetId = null;
    const listOfVersions = getState(this.store).dataset.versions;
    if (listOfVersions && listOfVersions.length) {
      latestVersion = listOfVersions[listOfVersions.length - 1].dataSetVersion;
      datasetId = getState(this.store).dataset.current.dataset.id;
    }
    return this.actions.pipe(
      ofType(loadDatasetByLatestVersion),
      switchMap(() => {
          if (!datasetId || !latestVersion) {
            return of({ type: 'empty action' });
          }
          return this.datasetService.searchByIdAndVersion(datasetId, latestVersion).pipe(
            mergeMap((dataset: DatasetModel) => [
              (populateCurrentDataset({ dataset }))
            ])
          );
        }
      )
    );
  });

  fetchAllDatasetVersions = createEffect(() => {
    return this.actions.pipe(
      ofType(fetchAllDatasetVersions),
      switchMap(({ datasetId }) => this.datasetService.getDatasetVersions(datasetId).pipe(
        mergeMap((versions: DatasetVersion[]) => [
          (loadAllDatasetVersions({ versions }))
        ])
      ))
    );
  });

  loadByIdAndVersion = createEffect(() => {
    return this.actions.pipe(
      ofType(loadByIdAndVersion),
      switchMap(({ id, version }) => {
        return this.datasetService.searchByIdAndVersion(id, version)
          .pipe(
            mergeMap((res: any) => [
              (populateCurrentDataset({ dataset: res }))
            ])
          );
      })
    );
  });

  createDataset = createEffect(() => {
    return this.actions.pipe(
      ofType(createDataset),
      mergeMap(({ dataset }) =>
        this.datasetService.create(dataset).pipe(
          mergeMap((res: DatasetModel) => [
            (addDataset({ dataset: res })),
            (pendingCreateDataset({ pending: false })),
            (populateCurrentDataset({ dataset: res })),
            (successCreateDataset({ success: true })),
            (globalLoading(false))
          ]),
          catchError(err => {
            return [(globalLoading(false))];
          }))));
  });

  updateDataset = createEffect(() => {
    return this.actions.pipe(
      ofType(updateDataset),
      mergeMap(({ dataset, success }) =>
        this.datasetService.update(dataset).pipe(
          mergeMap((res: DatasetModel) => {
            return [
              (populateCurrentDataset({ dataset: res })),
              (pendingCreateDataset({ pending: false })),
              (successCreateDataset({ success: success !== undefined ? success : true })),
              (globalLoading(false))
            ];
          }),
          catchError(err => {
            return [(globalLoading(false))
            ];
          }))));
  });

  searchById = createEffect(() => {
    return this.actions.pipe(
      ofType(searchDatasetById),
      mergeMap(({ id }) => {
          if (!id) {
            return of({ type: 'empty action' });
          }
          // const hasDatasetDataListPermission = this.permissionsService.getPermission('DatasetListRead');
          const hasDatasetDataListPermission = true;
          return this.datasetService.searchById(id).pipe(
            mergeMap((res: DatasetModel) => [
              (populateCurrentDataset({ dataset: res })),
              (hasDatasetDataListPermission ? getDatasetDataLists({ datasetId: res.id }) : globalLoading(false)),
              (globalLoading(false))
            ]),
            catchError(err => {
              return [(globalLoading(false))];
            }));
        }
      ));
  });

  loadDatasetTypes = createEffect(() => {
    return this.actions.pipe(
      ofType(loadDatasetTypes),
      mergeMap(() => {
        const listTypes: DatasetDataTypeModel[] = getState(this.store).dataset.types;
        if (listTypes.length) {
          return of((updateDatasetTypes({ types: listTypes })));
        } else {
          return this.datasetService.loadDatasetDataTypes().pipe(
            mergeMap((res: DatasetDataTypeModel[]) =>
              [(updateDatasetTypes({ types: res }))]),
            catchError(err => {
              return [];
            })
          );
        }
      }));
  });

  loadList = createEffect(() => {
    return this.actions.pipe(
      ofType(loadDatasetList),
      mergeMap(() => {
        return this.datasetService.getDatasetList().pipe(
          mergeMap(res => [
            (updateDatasetList({ datasetList: res })),
            (globalLoading(false))
          ]),
          catchError(err => {
            return [(globalLoading(false))];
          })
        );
      }));
  });

  deleteDataset = createEffect(() => {
    return this.actions.pipe(
      ofType(deleteDataset),
      mergeMap(({ id }) =>
        this.datasetService.deleteDataset(id).pipe(
          mergeMap(res => {
            return [
              (removeDataset({ id })),
              (messagePopup({ message: 'Successfully delete dataset' }))
            ];
          }),
          catchError(err => {
            return [(errorPopup({ error: 'There is a problem with deleting this dataset' }))];
          })
        )));
  });

  populateCurrentDataset = createEffect(() => {
    return this.actions.pipe(
      ofType(populateCurrentDataset),
      mergeMap(({ dataset }) => {
        return [(populateCurrentDatasetSnapshot({ dataset }))];
      }),
      catchError(() => []));
  });

  refreshCurrentDataset = createEffect(() => {
    return this.actions.pipe(
      ofType(refreshCurrentDataset),
      mergeMap(() => {
        const id = getState(this.store).dataset.current.dataset.id;
        return [(searchDatasetById({ id }))];
      }),
      catchError(() => []));
  });

  updatePendingState = createEffect(() => {
    return this.actions.pipe(
      ofType(updateDatasetField, populateCurrentDatasetSnapshot, successCreateDataset),
      mergeMap(() => {
        const hasChanges = !isEqual(getState(this.store).dataset.current.dataset, getState(this.store).dataset.current.datasetSnapshot);
        return [(checkCurrentDatasetForPendingChanges({ hasChanges }))];
      }),
      catchError(() => []));
  });

  createDatasetList = createEffect(() => {
    return this.actions.pipe(
      ofType(createDatasetList),
      mergeMap(({ datasetId, title }) => {
        return this.datasetService.createDatasetList(title, datasetId).pipe(
          mergeMap((dsl: IDatasetList) => {
            return [
              (messagePopup({ message: 'Dataset List Successfully created' })),
              (getDatasetDataLists({ datasetId: dsl.datasetId })),
              (loadCurrentDatasetDataList({ list: dsl }))
            ]
          })
        )
      }),
      catchError(() => []));
  });


  // getDatasetDataLists = createEffect(() => {
  //   return this.actions.pipe(
  //     ofType(getDatasetDataLists),
  //     mergeMap(({ datasetId }) => {
  //       return this.datasetService.getDatasetLists(datasetId).pipe(
  //         mergeMap((lists: IDatasetList[]) => {
  //           return [(loadDatasetDataLists({ lists }))]
  //         })
  //       )
  //     }),
  //     catchError(() => [(loadDatasetDataLists({ lists: [] }))]));
  // });
  deleteDatasetDataLists = createEffect(() => {
    return this.actions.pipe(
      ofType(deleteDatasetDataList),
      mergeMap(({ list }) => {
        return this.datasetService.deleteDatasetList(list.id).pipe(
          mergeMap(() => {
            return [
              (messagePopup({ message: 'Dataset List Successfully deleted' })),
              (getDatasetDataLists({ datasetId: list.datasetId })),
              (resetCurrentDatasetDataList())
            ]
          })
        )
      }),
      catchError(() => [(loadDatasetDataLists({ lists: [] }))]));
  });

  updateDatasetDataList = createEffect(() => {
    return this.actions.pipe(
      ofType(updateDatasetDataList),
      mergeMap(() => {
        const current = getState(this.store).dataset.current.datasetLists.current;
        return this.datasetService.updateDatasetList(current).pipe(
          mergeMap((list) => {
            return [
              (messagePopup({ message: 'Dataset List Successfully updated!' })),
              (loadCurrentDatasetDataList({ list })),
              (getDatasetDataLists({ datasetId: list.datasetId }))
            ]
          })
        )
      }),
      catchError(() => [(errorPopup({ error: 'There is an issue with updating Dataset list' }))]));
  });


}

