import { appState } from '../models/app.state';
import { CONTROL_INITIAL_STATE, ControlState } from '../models/control.state';
import * as controlActions from '../actions/control.actions'
import { Action, createReducer, on } from "@ngrx/store";

export function controlReducer(state: ControlState, action: Action) {
  return _controlReducer(state, action);
}

const _controlReducer = createReducer(appState.control,
  on(controlActions.resetCurrentControl, (state) => ({
    ...state,
    current: {
      ...state.current,
      ...CONTROL_INITIAL_STATE(),
    }
  })),
  on(controlActions.populateCurrentControl, (state, { control }) => ({
    ...state,
    current: { ...state.current, control: control }
  })),
  on(controlActions.updateControlField, (state, { field, value }) => ({
    ...state,
    current: {
      ...state.current,
      control: {
        ...state.current.control,
        [field]: value
      }
    }
  })),
  on(controlActions.updateControlSettingsField, (state, { settingsField, value }) => ({
    ...state, current: {
      ...state.current,
      control: {
        ...state.current.control, settings: {
          ...state.current.control.settings,
          [settingsField]: value
        }
      }
    }
  })),
);
