import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import { loadSettings, populateSettings, selectSettingsState } from '../actions/settings.actions';
import { globalLoading } from '../actions/ui.actions';
import { PharHttp } from '../../core/http/http-client.service';
import { ICountry } from '../../shared/models/country.interface';
import { AppState } from '../models/app.state';
import { Store } from '@ngrx/store';
import { ITimeZone } from '../../shared/models/timezone.interface';
import { ILanguage } from '../../shared/models/language.interface';

@Injectable()
export class SettingsEffects {

  private actions = inject(Actions);
  private http = inject(PharHttp);
  private store: Store<AppState> = inject(Store<AppState>);

  loadCountries = createEffect(() => {
    return this.actions.pipe(
      ofType(loadSettings),
      withLatestFrom(this.store.select(selectSettingsState)),
      mergeMap(([action, settings]) => {
          if (!settings.countries.length || !settings.timezones.length || !settings.languages.length) {
            return this.http.get('./assets/settings.json').pipe(
              mergeMap((res: { countries: ICountry[], timezones: ITimeZone[], languages: ILanguage[] }) => [
                (populateSettings(res)),
                (globalLoading(false))
              ]),
              catchError(() => {
                return [(globalLoading(false))];
              })
            )
          }
          return [globalLoading(false)];
        }
      ));
  });


}

