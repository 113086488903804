import { inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import * as fromQuestionActions from './question.actions';

import { catchError, mergeMap } from 'rxjs/operators';
import { QuestionModel } from '../question.model';
import { QuestionService } from '../question.service';
import { Store } from '@ngrx/store';
import { cloneDeep, isArray, isEqual } from 'lodash-es';
import { AppState, getState } from '../../store/models/app.state';
import { globalLoading, messagePopup } from '../../store/actions/ui.actions';
import { selectCurrentFormStateForm } from '../../form/store/form.state';
import { UtilsService } from '../../core/utils.service';
import { ControlModel } from '../control.model';
import { FormModel } from '../../form/form.model';

@Injectable()
export class QuestionEffects {

  actions = inject(Actions)
  store = inject(Store<AppState>)
  questionService = inject(QuestionService)
  utilsService: UtilsService = inject(UtilsService);
  loadQuestionListByDataset = createEffect(() => {
    return this.actions.pipe(
      ofType(fromQuestionActions.loadQuestionListByDataset),
      mergeMap(({ datasetId }) =>
        this.questionService.getQuestionList(datasetId).pipe(
          mergeMap((res: QuestionModel[]) => [
            (fromQuestionActions.updateQuestionListByDataset({
              questionList: res || []
            }))
          ]),
          catchError(err => {
            return [];
          })
        )
      ));
  });

  loadQuestionListByForm = createEffect(() => {
    return this.actions.pipe(
      ofType(fromQuestionActions.loadQuestionListByForm),
      mergeMap(({ formId }) =>
        this.questionService.getQuestionListByForm(formId).pipe(
          mergeMap((res: QuestionModel[]) => [
            (fromQuestionActions.updateQuestionListByForm({
              questionList: res || []
            }))
          ]),
          catchError(err => {
            return [];
          })
        )
      ));
  });

  seachQuestionById = createEffect(() => {
    return this.actions.pipe(
      ofType(fromQuestionActions.searchQuestionById),
      mergeMap(({ id }) =>
        this.questionService.searchById(id).pipe(
          mergeMap((res: QuestionModel) => [
            res ? (fromQuestionActions.populateCurrentQuestion({ question: res })) : (fromQuestionActions.resetCurrentQuestion())
          ]),
          catchError(err => {
            return [];
          })
        )
      ));
  });

  createQuestion = createEffect(() => {
    return this.actions.pipe(
      ofType(fromQuestionActions.createQuestion),
      mergeMap(({ question, isAutoCreate }) =>
        this.questionService.create(question).pipe(
          mergeMap((res: QuestionModel) => {
            return [
              (fromQuestionActions.pendingCreateUpdateQuestion({ pending: false })),
              (fromQuestionActions.successCreateUpdateQuestion({
                success: true,
                response: res,
                isAutoCreate: isAutoCreate
              })),
              (fromQuestionActions.resetCurrentQuestion()),
              (globalLoading(false)),
              res.formId ? (fromQuestionActions.addQuestionInListForm({ question: res })) : (fromQuestionActions.addQuestionInListDataset({ question: res }))
            ];
          }),
          catchError(err => {
            this.questionService.setQuestionAutoCreateMode(false);
            return [
              (globalLoading(false)),
            ];
          })
        )
      ));
  });

  updateQuestion = createEffect(() => {
    return this.actions.pipe(
      ofType(fromQuestionActions.updateQuestion),
      concatLatestFrom(action => this.store.select(selectCurrentFormStateForm)),

      mergeMap(([{ question, isAutoCreate }, form]) => {
          return this.questionService.update(this.updateQuestionAndControlUUID(question, form) as QuestionModel).pipe(
            mergeMap((res: QuestionModel) => {
              const actions = [
                (globalLoading(false)),
                (fromQuestionActions.updateQuestionInList({ question: res })),
                (fromQuestionActions.pendingCreateUpdateQuestion({ pending: false })),
                (fromQuestionActions.successCreateUpdateQuestion({ success: true })),
                (fromQuestionActions.populateCurrentQuestion({ question: res })),
                (fromQuestionActions.autocreateQuestionSuccess()),
              ];

              if (!isAutoCreate) {
                actions.pop();
              }

              return actions;
            }),
            catchError(err => {
              this.questionService.setQuestionAutoCreateMode(false);
              return [
                (globalLoading(false)),
              ];
            })
          )
        }
      ));
  });

  updateQuestions = createEffect(() => {
    return this.actions.pipe(
      ofType(fromQuestionActions.updateQuestions),
      concatLatestFrom(action => this.store.select(selectCurrentFormStateForm)),
      mergeMap(([{ questions }, form]) =>

        this.questionService.updateMultiple(this.updateQuestionAndControlUUID(questions, form) as QuestionModel[]).pipe(
          mergeMap((res: QuestionModel[]) => {
            return [
              (globalLoading(false)),
              (fromQuestionActions.updateQuestionsInList({ questions: res })),
            ];
          }),
          catchError(err => {
            return [
              (globalLoading(false)),
            ];
          })
        )
      ));
  });

  deleteQuestion = createEffect(() => {
    return this.actions.pipe(
      ofType(fromQuestionActions.deleteQuestion),
      mergeMap(({ id, isFormQuestion }) =>
        this.questionService.deleteQuestion(id, isFormQuestion).pipe(
          mergeMap(res => [
            (fromQuestionActions.removeQuestion({ id })),
            (messagePopup({ message: 'Question removed.' }))
          ]),
          catchError(err => {
            return [];
          })
        )
      ));
  });

  populateCurrentQuestion = createEffect(() => {
    return this.actions.pipe(
      ofType(fromQuestionActions.populateCurrentQuestion),
      mergeMap(({ question }) => {

        return [(fromQuestionActions.populateCurrentQuestionSnapshot({
          question
        }))];
      }),
      catchError(() => [])
    );
  });

  updatePendingChanges = createEffect(() => {
    return this.actions.pipe(
      ofType(fromQuestionActions.populateCurrentQuestionSnapshot,
        fromQuestionActions.successCreateUpdateQuestion,
        fromQuestionActions.updateQuestionField,
        fromQuestionActions.updateQuestionFields,
        fromQuestionActions.updateQuestion),
      mergeMap((actions) => {
        const current: QuestionModel = cloneDeep(getState(this.store).question.current.question);
        const snapshot: QuestionModel = getState(this.store).question.current.questionSnapshot;
        current.controls = this.questionService.removeDropZonesDeep(current.controls);
        snapshot.controls = this.questionService.removeDropZonesDeep(snapshot.controls);

        const hasChanges: boolean = !isEqual(current, snapshot);
        return [(fromQuestionActions.checkCurrentQuestionForPendingChanges({ hasChanges }))];
      }),
      catchError(() => [])
    );
  });

  successCreateUpdateQuestion = createEffect(() => {
    return this.actions.pipe(
      ofType(fromQuestionActions.successCreateUpdateQuestion),
      mergeMap((data) => {
        return [(messagePopup({ message: this.questionService.isQuestionAutoCreateMode() ? '' : 'Question updated.' }))];
      }),
      catchError(() => [])
    );
  });

  deleteMultipleQuestions = createEffect(() => {
    return this.actions.pipe(
      ofType(fromQuestionActions.deleteMultipleQuestions),
      mergeMap((data) => {
        return this.questionService.deleteQuestions(data.ids).pipe(
          mergeMap(
            () => [(fromQuestionActions.removeQuestion({ id: data.ids }))],
          ),
          catchError(() => [])
        )
      }),
    );
  });

  private updateQuestionAndControlUUID(question: QuestionModel | QuestionModel[], form: FormModel): QuestionModel | QuestionModel[] {
    if (!form.isAmended) {
      return question;
    }

    // if the form is amended we need to update the question guid and all corresponding controls with the new guid

    if (isArray(question)) {
      return question.map((q: QuestionModel) => this.updateQuestionAndControlUUIDFn(q));
    }
    return this.updateQuestionAndControlUUIDFn(question as QuestionModel);
  }

  private updateQuestionAndControlUUIDFn(question: QuestionModel): QuestionModel {
    const clonedQuestion = cloneDeep(question);
    const newUUID = this.utilsService.generateUUID();
    clonedQuestion.questionGuid = newUUID;
    if (clonedQuestion.controls.length) {
      clonedQuestion.controls = clonedQuestion.controls.map((control: ControlModel) => ({
        ...control,
        question_uuid: newUUID
      }));
    }
    return clonedQuestion;
  }
}
