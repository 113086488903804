import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  updateProjectList,
  deleteProject,
  removeProject,
  populateCurrentProject,
  searchProjectById,
  createProject,
  updateProject,
  pendingCreateProject,
  successCreateProject,
  addProject,
  loadProjectList,
  checkCurrentProjectForPendingChanges,
  getRecordForms,
  populateRecordForms,
  populateCurrentProjectSnapshot,
  refreshCurrentProject,
  updateProjectField,
  updateProjectFields,
  getStudyAdminList,
  populateStudyAdminList,
  deleteStudyAdmin,
  removeStudyAdminFromList,
  loadProjectMatrixItemsCommentsCounters,
  populateProjectMatrixItemsCommentsCounters
} from './project.actions';


import { AppState, getState } from '../../store/models/app.state';
import { Store } from '@ngrx/store';
import { ProjectService } from '../project.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { ProjectModel } from '../project.model';
import { errorPopup, globalLoading, messagePopup } from '../../store/actions/ui.actions';
import { isEqual, omit } from 'lodash-es';

@Injectable()
export class ProjectEffect {
  actions = inject(Actions);
  projectService = inject(ProjectService);
  store = inject(Store<AppState>);

  searchProjectById = createEffect(() => {
    return this.actions.pipe(
      ofType(searchProjectById),
      mergeMap(({ id }) =>
        this.projectService.searchById(id).pipe(
          mergeMap((res: ProjectModel) => [
            (populateCurrentProject({ project: res })),
            (populateCurrentProjectSnapshot({ project: res })),
            (globalLoading(false))
          ]),
          catchError(err => {
            return [(globalLoading(false))];
          })
        )
      ));
  });

  createProject = createEffect(() => {
    return this.actions.pipe(
      ofType(createProject),
      mergeMap(({ project }) =>
        this.projectService.create(project).pipe(
          mergeMap((res: ProjectModel) => [
            (addProject({ project: res })),
            (pendingCreateProject({ pending: false })),
            (populateCurrentProject({ project: res })),
            (populateCurrentProjectSnapshot({ project: res })),
            (successCreateProject({ success: true })),
            (globalLoading(false))
          ]),
          catchError(err => {
            return [(globalLoading(false))];
          })
        )
      ));
  });

  updateProject = createEffect(() => {
    return this.actions.pipe(
      ofType<ReturnType<typeof updateProject>>(updateProject),
      mergeMap(({ project }) =>
        this.projectService.update(project).pipe(
          mergeMap(res => [
            (populateCurrentProject({ project: res })),
            (populateCurrentProjectSnapshot({ project: res })),
            (pendingCreateProject({ pending: false })),
            (successCreateProject({ success: true })),
            (messagePopup({ message: 'Study updated' })),
            (globalLoading(false))
          ]),
          catchError(() => [
            (globalLoading(false)),
            (successCreateProject({ success: false })),
            (pendingCreateProject({ pending: false })),
          ]))
      ));
  });

  loadProjectList = createEffect(() => {
    return this.actions.pipe(
      ofType(loadProjectList),
      mergeMap(() =>
        this.projectService.getProjectList().pipe(
          mergeMap(res => [
            (updateProjectList({ projectList: res })),
            (globalLoading(false))
          ]),
          catchError(() => [
            (globalLoading(false))
          ])
        )));
  });

  deleteProject = createEffect(() => {
    return this.actions.pipe(
      ofType(deleteProject),
      mergeMap(({ id }) =>
        this.projectService.deleteProject(id).pipe(
          mergeMap(() => [
            (removeProject({ id })),
            (messagePopup({ message: 'Successfully delete a Study' }))
          ]),
          catchError(() => [(errorPopup({ error: 'There is a problem with deleting this Study' }))])
        )
      ));
  });
  getStudyAdmins = createEffect(() => {
    return this.actions.pipe(
      ofType(getStudyAdminList),
      mergeMap(({ studyId }) =>
        this.projectService.getStudyAdmins(studyId).pipe(
          mergeMap(res => [
            (populateStudyAdminList({ admins: res }))
          ]),
          catchError(() => [])
        )
      ));
  });

  deleteStudyAdmin = createEffect(() => {
    return this.actions.pipe(
      ofType(deleteStudyAdmin),
      mergeMap(({ id }) =>
        this.projectService.deleteStudyAdmin(id).pipe(
          mergeMap(res => [
            (messagePopup({ message: 'Successfully delete a Study admin' })),
            (removeStudyAdminFromList({ id: id }))
          ]),
          catchError(() => [])
        )
      ));
  });

  getRecordForms = createEffect(() => {
    return this.actions.pipe(
      ofType(getRecordForms),
      mergeMap(({ datasetId, projectId }) =>
        this.projectService.getRecordFormsPerProject(projectId, datasetId).pipe(
          mergeMap(res => [
            (populateRecordForms({ recordForms: res }))
          ]),
          catchError(() => [])
        )
      ));
  });

  refreshCurrentProject = createEffect(() => {
    return this.actions.pipe(
      ofType(refreshCurrentProject),
      mergeMap(() => [
        (searchProjectById({
          id: getState(this.store).project.current.project.id
        }))
      ]),
      catchError(() => []));
  });

  updateProjectField = createEffect(() => {
    return this.actions.pipe(
      ofType(updateProjectField, updateProjectFields, successCreateProject, populateCurrentProjectSnapshot),
      mergeMap(() => {
        const current = omit(getState(this.store).project.current.project, ['userLastNameField', 'userFirstNameField']);
        const snapshot = omit(getState(this.store).project.current.projectSnapshot, ['userLastNameField', 'userFirstNameField']);
        return [
          checkCurrentProjectForPendingChanges({
            hasChanges: !isEqual(current, snapshot)
          })
        ]
      }),
      catchError(() => []));
  });


  loadProjectMatrixItemsCommentsCounter = createEffect(() => {
    return this.actions.pipe(
      ofType(loadProjectMatrixItemsCommentsCounters),
      mergeMap(({ projectId }) =>
        this.projectService.getProjectMatrixItemsCommentsCounters(projectId).pipe(
          mergeMap((comments) => [
            (populateProjectMatrixItemsCommentsCounters({ counters: comments })),
          ]),
          catchError(() => [])
        )
      ));
  })

}

