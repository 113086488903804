import * as siteActions from './site.actions';
import { cloneDeep } from 'lodash-es';
import { Action, createReducer, on } from '@ngrx/store';
import { appStateSite, SITE_INITIAL_STATE, SiteState } from './site.state';

const reducer = createReducer(appStateSite.site,

  on(siteActions.populateCurrentSite, (state, { site }) => ({
    ...state,
    current: {
      ...state.current,
      site,
    },
  })),
  on(siteActions.populateCurrentSiteSnapshot, (state, { site }) => ({
    ...state,
    current: { ...state.current, siteSnapshot: cloneDeep(site) }
  })),

  on(siteActions.pendingCreateSite, (state, { pending }) => ({
    ...state, current: { ...state.current, pending }
  })),
  on(siteActions.dispatchedCreateSite, (state, { dispatched }) => ({
    ...state, current: { ...state.current, dispatched }
  })),
  on(siteActions.successCreateSite, (state, { success }) => ({
    ...state, current: { ...state.current, success }
  })),

  on(siteActions.updateSiteField, (state, { field, value }) => ({
    ...state,
    current: { ...state.current, site: { ...state.current.site, [field]: value } }
  })),
  on(siteActions.updateSiteFields, (state, { partialSite }) => ({
    ...state,
    current: { ...state.current, site: { ...state.current.site, ...partialSite } }
  })),
  on(siteActions.resetCurrentSite, (state) => ({
    ...state,
    current: {
      ...state.current,
      site: cloneDeep(SITE_INITIAL_STATE),
      siteSnapshot: cloneDeep(SITE_INITIAL_STATE),
    },
  })),
  on(siteActions.populateSitesList, (state, { sites }) => ({
    ...state,
    list: sites
  })),
);

export function siteReducer(state: SiteState, action: Action): SiteState {
  return reducer(state, action);
}
