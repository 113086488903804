import { QUESTION_INITIAL_STATE, QuestionState } from './question.state';
import * as questionActions from './question.actions';
import { appState } from '../../store/models/app.state';
import { cloneDeep, isArray } from 'lodash-es';
import { Action, createReducer, on } from '@ngrx/store';
import { QuestionModel } from '../question.model';

const reducer = createReducer(appState.question,
  on(questionActions.updateQuestionListByDataset, (state, { questionList }) => ({
    ...state,
    list: { form: state.list.form, dataset: questionList }
  })),
  on(questionActions.updateQuestionListByForm, (state, { questionList }) => ({
    ...state,
    list: { dataset: state.list.dataset, form: questionList }
  })),
  on(questionActions.removeQuestion, (state, { id }) => {
    return removeQuestion(state, id);
  }),
  on(questionActions.resetCurrentQuestion, (state) => ({
    ...state, current: {
      ...state.current,
      question: { ...QUESTION_INITIAL_STATE },
      questionSnapshot: { ...QUESTION_INITIAL_STATE },
      pendingChanges: false
    }
  })),
  on(questionActions.populateCurrentQuestion, (state, { question }) => ({
    ...state,
    current: { ...state.current, question }
  })),
  on(questionActions.updateQuestionField, (state, { value, field }) => ({
    ...state,
    current: { ...state.current, question: { ...state.current.question, [field]: value } }
  })),
  on(questionActions.pendingCreateUpdateQuestion, (state, { pending }) => ({
    ...state,
    current: { ...state.current, pending }
  })),
  on(questionActions.dispatchedCreateUpdateQuestion, (state, { dispatched }) => ({
    ...state,
    current: { ...state.current, dispatched }
  })),
  on(questionActions.successCreateUpdateQuestion, (state, { success }) => ({
    ...state,
    current: { ...state.current, success }
  })),
  on(questionActions.updateQuestionInList, (state, { question }) => {
    return updateQuestionInListFn(state, question);
  }),
  on(questionActions.updateQuestionsInList, (state, { questions }) => {
    let stateCopy = cloneDeep(state);
    questions.forEach(q => {
      stateCopy = updateQuestionInListFn(stateCopy, q);
    })
    return stateCopy;

  }),
  on(questionActions.addQuestionInListDataset, (state, { question }) => ({
    ...state,
    list: {
      form: state.list.form,
      dataset: [...[...state.list.dataset], { ...question }]
    }
  })),
  on(questionActions.addQuestionInListForm, (state, { question }) => ({
    ...state,
    list: {
      dataset: state.list.dataset,
      form: [...[...state.list.form], { ...question }]
    }
  })),
  on(questionActions.populateCurrentQuestionSnapshot, (state, { question }) => ({
    ...state,
    current: { ...state.current, questionSnapshot: cloneDeep(question) }
  })),
  on(questionActions.checkCurrentQuestionForPendingChanges, (state, { hasChanges }) => ({
    ...state,
    current: { ...state.current, pendingChanges: hasChanges }
  })),
  on(questionActions.updateQuestionFields, (state, { fields }) => ({
    ...state,
    current: {
      ...state.current,
      question: {
        ...state.current.question,
        ...fields,
      }
    }
  }))
);

export function questionReducer(state: QuestionState = appState.question, action: Action): QuestionState {
  return reducer(state, action);
}


function removeQuestion(state: QuestionState, id: number | number[]): QuestionState {
  let updatedState = state;
  if (isArray(id)) {
    (id as number[]).forEach((idItem: number) => {
      updatedState = removeQuestionItem(updatedState, idItem);
    })
  } else {
    updatedState = removeQuestionItem(state, id as number);
  }

  return updatedState;
}

function removeQuestionItem(state: QuestionState, id: number) {
  let elementRemoved = false;
  const removeList = state.list;
  const datasetListRemoveIndex = state.list.dataset.findIndex(d => d.id === id);
  const formListRemoveIndex = state.list.form.findIndex(d => d.id === id);
  if (datasetListRemoveIndex > -1) {
    removeList.dataset = state.list.dataset.slice(0, datasetListRemoveIndex).concat(state.list.dataset.slice(datasetListRemoveIndex + 1));
    elementRemoved = true;
  }
  if (formListRemoveIndex > -1) {
    removeList.form = state.list.form.slice(0, formListRemoveIndex).concat(state.list.form.slice(formListRemoveIndex + 1));
    elementRemoved = true;
  }

  return elementRemoved ? { ...state, list: removeList } : state;
}

function updateQuestionInListFn(state: QuestionState, question: QuestionModel): QuestionState {
  const datasetListIndex = state.list.dataset.findIndex(d => d.id === question.id);
  const formListIndex = state.list.form.findIndex(d => d.id === question.id);
  const updateList = state.list;
  let hasChanges = false;
  if (datasetListIndex > -1) {
    updateList.dataset = [...[...state.list.dataset.slice(0, datasetListIndex)],
      { ...question },
      ...[...state.list.dataset.slice(datasetListIndex + 1)]];
    hasChanges = true;
  }
  if (formListIndex > -1) {
    updateList.form = [...[...state.list.form.slice(0, formListIndex)],
      { ...question },
      ...[...state.list.form.slice(formListIndex + 1)]];
    hasChanges = true;
  }
  return hasChanges ? { ...state, list: updateList } : state;
}
