import { ActionReducerMap } from '@ngrx/store';
import { AppState } from '../models/app.state';
import { uiReducer } from './ui.reducer';
import { datasetReducer } from '../../dataset/store/dataset.reducer';
import { userReducer } from '../../user/store/user.reducer';
import { projectReducer } from '../../project/store/project.reducer';
import { formReducer } from '../../form/store/form.reducer';
import { questionReducer } from '../../question/store/question.reducer';
import { controlReducer } from './control.reducer';
import { assignReducer } from '../../assign/store/assign.reducers';
/*import { permissionReducer } from '../../permission/store/permission.reducers';*/
import { auditTrailReducer } from '../../audit/store/audit.reducer';
import { eventReducer } from '../../events/store/event.reducer';
import { settingsReducer } from './settings.reducer';
import { siteReducer } from '../../site/store/site.reducer';
import { roleReducer } from '../../roles-and-permissions/store/roles-and-permissions.reducer';

/**
 * all reducers
 * @type ActionReducerMap<AppStateModel>
 */
export const reducers: ActionReducerMap<AppState> = {
  ui: uiReducer,
  dataset: datasetReducer,
  form: formReducer,

  user: userReducer,
  project: projectReducer,
  event: eventReducer,
  question: questionReducer,
  control: controlReducer,
  assign: assignReducer,
  /*permission: permissionReducer,*/
  auditTrail: auditTrailReducer,
  settings: settingsReducer,
  site: siteReducer,
  roles: roleReducer,
  // group: groupReducer,
  // role: roleReducer,
  // record: recordReducer,
  // organization: organizationReducer,
  // datasetViews: datasetViewReducer,
  // customers: customersReducer,
};
