import { inject, Injectable } from '@angular/core';
import { FormService } from '../form.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { isEqual } from 'lodash-es';
import {
  checkCurrentFormForPendingChanges,
  createForm,
  deleteForm,
  duplicateForm,
  formUpdateQuestion,
  loadFormList,
  loadFormListByDatasetId,
  loadFormListByStatuses, loadFormListWithStudies,
  loadProjectContextFormList,
  pendingCreateUpdateForm,
  populateCurrentForm,
  populateCurrentFormSnapshot,
  refreshCurrentForm,
  removeForm,
  searchFormById,
  successCreateUpdateForm,
  updateForm,
  updateFormField,
  updateFormInList,
  updateFormList
} from './form.actions';

import { catchError, map, mergeMap } from 'rxjs/operators';
import { FormModel, FormStatusEnum, FormTypeEnum } from '../form.model';
import { errorPopup, globalLoading, messagePopup } from '../../store/actions/ui.actions';
import { Store } from '@ngrx/store';
import { AppState, getState } from '../../store/models/app.state';
import { FormRenderingMode } from '../_form-settings/form-rendering-mode.enum';
import { QuestionService } from '../../question/question.service';

@Injectable()
export class FormEffects {
  store = inject(Store<AppState>);
  actions = inject(Actions);
  formService = inject(FormService);
  questionService = inject(QuestionService);
  loadFormsList = createEffect(() => {
    return this.actions.pipe(
      ofType(loadFormList),
      mergeMap(() =>
        this.formService.getFormList().pipe(
          mergeMap((res: FormModel[]) =>
            [(updateFormList({
              formList: res || []
            })),
              (globalLoading(false))]),
          catchError(() => {
            return [(globalLoading(false))];
          })
        )
      ));
  });
  loadFormsListByStatuses = createEffect(() => {
    return this.actions.pipe(
      ofType(loadFormListByStatuses),
      mergeMap(({ statuses }) =>
        this.formService.getFormList(statuses).pipe(
          mergeMap((res: FormModel[]) => [
            updateFormList({
              formList: res || []
            }),
            globalLoading(false),
          ]),
          catchError(() => {
            return [(globalLoading(false))];
          })
        )
      ));
  });
  loadFormsListWithStudies = createEffect(() => {
    return this.actions.pipe(
      ofType(loadFormListWithStudies),
      mergeMap(() =>
        this.formService.getFormListWithStudies().pipe(
          mergeMap((res: FormModel[]) => [
            updateFormList({
              formList: res || []
            }),
            globalLoading(false),
          ]),
          catchError(() => {
            return [(globalLoading(false))];
          })
        )
      ));
  });
  loadFormsListInProjectContext = createEffect(() => {
    return this.actions.pipe(
      ofType(loadProjectContextFormList),
      mergeMap(({ ids }) =>
        this.formService.getFormListInProjectContext(ids).pipe(
          mergeMap((res: FormModel[]) =>
            [(updateFormList({
              formList: res || []
            })),
              (globalLoading(false))]),
          catchError(() => {
            return [(globalLoading(false))];
          })
        )
      ));
  });
  loadFormsByDatasetId = createEffect(() => {
    return this.actions.pipe(
      ofType(loadFormListByDatasetId),
      mergeMap(({ id }) =>
        this.formService.getFormListByDatasetId(id).pipe(
          mergeMap((res: FormModel[]) =>
            [(updateFormList({
              formList: res || []
            })),
              (globalLoading(false))]),
          catchError(() => {
            return [(globalLoading(false))];
          })
        )
      ));
  });
  seachFormById = createEffect(() => {
    return this.actions.pipe(
      ofType(searchFormById),
      mergeMap(({ id }) =>
        this.formService.searchById(id).pipe(
          mergeMap((res: FormModel) => {
            if (!res.settings.hasOwnProperty('renderingMode')) {
              res.settings['renderingMode'] = FormRenderingMode.PAGE_BY_PAGE;
            } // handle the old forms that doesn't have rendering mode yet
            return [(populateCurrentForm({ form: res })),
              (populateCurrentFormSnapshot({ form: res })),
              (globalLoading(false))];
          }),
          catchError(() => {
            return [(globalLoading(false))];
          })
        )
      ));
  });
  createForm = createEffect(() => {
    return this.actions.pipe(
      ofType(createForm),
      mergeMap(({ form }) =>
        this.formService.create(form).pipe(
          mergeMap((res: FormModel) => [
            (populateCurrentForm({ form: res })),
            (populateCurrentFormSnapshot({ form: res })),
            (pendingCreateUpdateForm({ pending: false })),
            (successCreateUpdateForm({ success: true })),
            (messagePopup({ message: `${this.getRecordType(res)} Created` })),
            (globalLoading(false))
          ]),
          catchError(() => {
            return [ (globalLoading(false))];
          })
        )
      ));
  });
  updateForm = createEffect(() => {
    return this.actions.pipe(
      ofType(updateForm),
      mergeMap(({ form }) => {
          this.questionService.setQuestionAutoCreateMode(false);

          return this.formService.update(form).pipe(
            mergeMap((res: FormModel) => {
              return [
                (updateFormInList({ form: res })),
                (populateCurrentForm({ form: res })),
                (populateCurrentFormSnapshot({ form: res })),
                (pendingCreateUpdateForm({ pending: false })),
                (successCreateUpdateForm({ success: true })),
                (messagePopup({ message: `${this.getRecordType(res)} Updated` })),
                (globalLoading(false))
              ];
            }),
            catchError(() => {
              this.questionService.setQuestionAutoCreateMode(false);
              return [ (globalLoading(false))];
            })
          )
        }
      ));
  });
  deleteForm = createEffect(() => {
    return this.actions.pipe(
      ofType(deleteForm),
      mergeMap(({ id }) =>
        this.formService.deleteForm(id).pipe(
          mergeMap(() => [
            (removeForm({ id })),
            (messagePopup({ message: 'Successfully deleted' }))
          ]),
          catchError((error) => {
            return [];
          })
        )
      ));
  });

  duplicateForm = createEffect(() => {
    return this.actions.pipe(
      ofType(duplicateForm),
      mergeMap(({ id }) =>
        this.formService.duplicateForm(id).pipe(
          map((form) => {
            if (form.formStatus === FormStatusEnum.Released) {
              return [ FormStatusEnum.Released ];
            }

            return [
              FormStatusEnum.Draft,
              FormStatusEnum.PendingReview,
              FormStatusEnum.UnderReview,
              FormStatusEnum.Rejected,
            ];
          }),
          mergeMap((statuses) => [
            (loadFormListByStatuses({ statuses })),
            (messagePopup({ message: 'Form duplicated successfully' }))
          ]),
          catchError(() => {
            return [(errorPopup({ error: 'There is a problem with duplicate this form' }))];
          })
        )
      ));
  });
  updateFormField = createEffect(() => {
    return this.actions.pipe(
      ofType(updateFormField, successCreateUpdateForm, populateCurrentFormSnapshot, formUpdateQuestion),
      mergeMap(() => {
        return [
          checkCurrentFormForPendingChanges({
            changes: !isEqual(getState(this.store).form.current.form,
              getState(this.store).form.current.formSnapshot)
          })
        ];
      }),
      catchError(() => []));
  });
  refreshCurrentForm = createEffect(() => {
    return this.actions.pipe(
      ofType(refreshCurrentForm),
      mergeMap(() => {
        const formId =  getState(this.store).form.current.form.id;

        return [(searchFormById({ id: formId }))];
      }),
      catchError(() => {
        return [];
      }));
  });

  getRecordType(form: FormModel): string {
    switch (form.type) {
      case FormTypeEnum.Report:
        return 'Report';
      default:
        return 'Form';
    }
  }
}
